<template lang="pug">
.brand-integration-logo(
  :class="logoClass"
  :style="logoStyles"
  :title="$t(`integrations.${type}.name`)"
)
  img.brand-integration-logo-img(:src="getIntegrationLogo")
</template>
<script>
  import { getIntegrationColors } from '@om/integrations';

  export default {
    props: {
      type: {
        type: String,
      },
      justImage: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      logoClass() {
        let classes = '';

        if (this.justImage) classes += 'brand-integration-logo-just-image';
        if (this.hasBackground) classes += ' brand-integration-logo-has-background';
        if (this.isShopRenter) classes += ' brand-integration-shop-renter-logo';
        if (this.small) classes += ' brand-integration-logo-small';

        return classes;
      },
      getIntegrationLogo() {
        const png = [
          'automizy',
          'dotmailer',
          'hirlevelmanager',
          'interspire',
          'ladyBird',
          'listamester',
          'maileon',
          'mailWizz',
          'miniCrm',
          'robly',
          'salesAutopilot',
          'silihost',
          'soundest',
          'webGalamb3',
          'webGalamb4Plus',
          'highLevel',
          'selzy',
          'unas',
        ];
        const isPng = (t) => png.includes(t);

        let path;

        if (!this.type) {
          // deleted integration but has integration error
          path = require(`@om/integrations/src/assets/icons/deleted.png`);
          return path;
        }

        if (isPng(this.type)) {
          path = require(`@om/integrations/src/assets/icons/${this.type}.png`);
        } else {
          path = require(`@om/integrations/src/assets/icons/${this.type}.svg`);
        }

        return path;
      },

      backgroundColor() {
        const bgColor = getIntegrationColors(this.type);
        return this.justImage ? undefined : bgColor;
      },

      hasBackground() {
        return this.backgroundColor && this.backgroundColor !== 'white';
      },
      isShopRenter() {
        return this.type === 'shopRenter';
      },
      isNinetyPercentHeight() {
        const types = ['shopifyCustomer'];

        return types.includes(this.type);
      },
      logoStyles() {
        let height;

        if (this.isNinetyPercentHeight) {
          height = '90%';
        }

        if (this.isNinetyPercentHeight && this.small) {
          height = '100%';
        }

        return { background: this.backgroundColor, height };
      },
    },
  };
</script>

<style lang="sass">
  .brand-integration-logo
    height: 4.375rem
    width: 4.375rem
    display: flex
    align-items: center
    justify-content: center
    border-radius: 3px
    cursor: pointer

    &-img
      max-height: 100% !important

    &-has-background
      .brand-integration-logo-img
        height: 50%

    &-small
      width: 3.125rem
      min-width: 3.125rem
      height: 3.125rem
</style>
